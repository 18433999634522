export interface User {
    firstName: string;
    email: string;

    id?: string;
    companyId?: string;
    lastName?: string;
    company?: string;
    country?: string;
    isActive?: boolean;
    isInternet?: boolean;
    isAllowedUnground?: boolean;
    authorities?: UserAuthorities[];

}

export enum UserAuthorities {
    ANA3 = "ANA3",
    ANA3_GROUND = "ANA3_GROUND",
    ANA3_UNGROUND = "ANA3_UNGROUND",
    ANA3_UPLOAD_SPECTRA = "ANA3_UPLOAD_SPECTRA",
    ANA3_AMINOFEED = "ANA3_AMINOFEED",
    ANA3_UPLOAD_REFSCAN = "ANA3_UPLOAD_REFSCAN",
    ANA3_UPLOAD_STD = "ANA3_UPLOAD_STD",    
    ANA3_ALLOW_RESULT = "ANA3_ALLOW_RESULT",
    ADA3_ALLOW_ANA_CVT = "ADA3_ALLOW_ANA_CVT",
}

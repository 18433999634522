import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { EvoModule } from '@evonik/evo';
import { RouterModule } from '@angular/router';
import { InstrumentComponent } from './components/instrument/instrument.component';
import { InstrumentsComponent } from './components/instruments/instruments.component';
import { HasAuthorityPipe } from './components/pipes/has-authority.pipe';
import { LockScreenComponent } from './components/lock-screen/lock-screen.component';
import { AuthorizationErrorComponent } from './components/lock-screen/authorization-error/authorization-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfoScreenComponent } from './components/info-screen/info-screen.component';



@NgModule({
    declarations: [HeaderComponent, InstrumentComponent, InstrumentsComponent, HasAuthorityPipe,
        LockScreenComponent, AuthorizationErrorComponent, InfoScreenComponent],
    imports: [
        CommonModule,
        EvoModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        HeaderComponent,
        InstrumentComponent,
        InstrumentsComponent,
        LockScreenComponent,
        InfoScreenComponent,
        HasAuthorityPipe
    ]
})
export class CoreModule { }
